import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ExpandableBio } from '../../components';

import css from './ListingPage.module.css';

const { Money } = sdkTypes;
const ADDON_TEXT_COLLAPSED_LENGTH = 80;

const SectionAddonsMaybe = props => {
  const { intl, publicData } = props;

  const addons = publicData?.addons;
  const hasAddons = addons?.length > 0;
  return hasAddons ? (
    <div className={css.sectionAddons}>
      <h2 className={css.addonsTitle}>
        <FormattedMessage id="ListingPage.addonsTitle" />
      </h2>
      <div className={css.addons}>
        {addons.map(addon => {
          const formattedMoney = new Money(
            addon.price.amount,
            addon.price.currency
          );
          const formattedPrice = formatMoney(intl, formattedMoney);
          return (
            <div key={addon.key} className={css.addon}>
              <h3 className={css.addonTitle}>{addon.label}</h3>
              {addon.description ? (
                <ExpandableBio
                  className={css.addonText}
                  showMoreClassName={css.addonShowMore}
                  bio={addon.description}
                  bioCollapsedLength={ADDON_TEXT_COLLAPSED_LENGTH}
                />
              ) : null}
              <div className={css.addonPrice}>{formattedPrice}</div>
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};

export default SectionAddonsMaybe;
