import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const RulesMaybe = props => {
  const { formattedText } = props;

  return formattedText ? (
    <div className={css.sectionDamageDeposit}>
      <h2 className={css.damageDepositTitle}>
        <FormattedMessage id="ListingPage.rulesTitle" />
      </h2>
      <p className={css.damageDeposit}>
        <FormattedMessage
          id="ListingPage.rulesDescription"
          values={{
            text: (
              <span>
                {formattedText}
              </span>
            ),
          }}
        />
      </p>
    </div>
  ) : null;
};

RulesMaybe.defaultProps = {
  formattedText: null,
};

RulesMaybe.propTypes = {
  formattedText: string,
};

export default RulesMaybe;
