import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import classNames from 'classnames';

import css from './ListingPage.module.css';

const { Money } = sdkTypes;

const SectionDayBasedPricingsMaybe = props => {
  const { intl, publicData, price, dayPricingOptions } = props;

  const dayBasedPricings = publicData?.dayPricings;
  const dynamicPricing = publicData?.dynamicPricing;

  if (dynamicPricing) {
    return null;
  }

  const hasDayBasedPricings = dayBasedPricings?.length > 0;
  return hasDayBasedPricings ? (
    <div className={css.sectionDayBasedPricings}>
      <h2 className={css.dayBasedPricingsTitle}>
        <FormattedMessage id="ListingPage.dayBasedPricingsTitle" />
      </h2>
      <div className={css.dayBasedPricings}>
        {dayPricingOptions.map(day => {
          const matchPricingOption = dayBasedPricings?.find(
            dayBasedPricing => dayBasedPricing.day === day.key
          );
          const formattedMoney = matchPricingOption
            ? new Money(
                matchPricingOption.price.amount,
                matchPricingOption.price.currency
              )
            : price;
          const formattedPrice = formatMoney(intl, formattedMoney);

          const classes = classNames(css.dayBasedPricing, {
            [css.blockedDayBasedPricing]: !matchPricingOption,
          });
          return (
            <div key={day.key} className={classes}>
              <div className={css.dayBasedPricingTitle}>{day.label}</div>
              <div className={css.dayBasedPricingPrice}>{formattedPrice}</div>
            </div>
          );
        })}
      </div>
      <div className={css.legend}>
        <div className={css.legendRow}>
          <span
            className={classNames(css.legendColor, css.legendAvailableColor)}
          />
          <span className={css.legendText}>
            <FormattedMessage id="ListingPage.dayPrice" />
          </span>
        </div>
        <div className={css.legendRow}>
          <span
            className={classNames(css.legendColor, css.legendBlockedColor)}
          />
          <span className={css.legendText}>
            <FormattedMessage id="ListingPage.basePrice" />
          </span>
        </div>
      </div>
    </div>
  ) : null;
};

export default SectionDayBasedPricingsMaybe;
