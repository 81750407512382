import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionDiscountsMaybe = props => {
  const { publicData } = props;

  const discounts = publicData?.discounts;
  const hasDiscounts = discounts?.length > 0;
  return hasDiscounts ? (
    <div className={css.sectionDiscounts}>
      <h2 className={css.discountsTitle}>
        <FormattedMessage id="ListingPage.discountsTitle" />
      </h2>
      <div className={css.discounts}>
        {discounts.map(discount => {
          return (
            <div key={discount.duration} className={css.discount}>
              <h3 className={css.discountTitle}>
                <FormattedMessage
                  id="ListingPage.discountTitle"
                  values={{ count: discount.duration }}
                />
              </h3>
              <p className={css.discountText}>
                <FormattedMessage
                  id="ListingPage.discountText"
                  values={{ count: discount.duration }}
                />
              </p>
              <div className={css.discountPercentage}>
                <FormattedMessage
                  id="ListingPage.discountPercentage"
                  values={{ percentage: discount.percentage }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};

export default SectionDiscountsMaybe;
