import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionDeliveryMaybe = props => {
  const { formattedDelivery } = props;

  return formattedDelivery ? (
    <div className={css.sectionDamageDeposit}>
      <h2 className={css.damageDepositTitle}>
        <FormattedMessage id="ListingPage.deliveryTitle" />
      </h2>
      <p className={css.delivery}>
        <FormattedMessage
          id="ListingPage.deliveryDescription"
          values={{
            price: (
              <span className={css.deliveryPrice}>
                {formattedDelivery}
              </span>
            ),
          }}
        />
      </p>
    </div>
  ) : null;
};

SectionDeliveryMaybe.defaultProps = {
  formattedDelivery: null,
};

SectionDeliveryMaybe.propTypes = {
  formattedDelivery: string,
};

export default SectionDeliveryMaybe;
